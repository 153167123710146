import React, { useEffect, useState } from "react"
import MainTitle from "../MainTitle/MainTitle"
import "./styles.scss"

export default function ErrorMessage() {
    const [content, setContent] = useState("")

    useEffect(() => {
        const urlParams =
            typeof window !== "undefined"
                ? new URLSearchParams(window.location.search)
                : null

        if (urlParams && urlParams.get("content")) {
            setContent(urlParams.get("content"))
        } else {
            setContent("We are really sorry but the page you're looking for does not exist.")
        }
    }, [])

    return (
        <>
            <MainTitle className="error-title">{content}</MainTitle>

            <div className="contact-us">
                <span>How to contact us</span>
                <a className="contact-email" href={`mailto:${process.env.GATSBY_CONTACT_EMAIL}`}>
                    {process.env.GATSBY_CONTACT_EMAIL}
                </a>
            </div>
        </>
    )
}
